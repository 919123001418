.MuiContainer-root {
    &.tribel-theme {
        --tribel-grey: #576573;
        --tribel-red:  #EA6B66;
        font-family: Montserrat, system-ui, sans-serif;
      }
    & .logo {
        padding-top: 0.5rem;
        margin-bottom: 2rem;
        width: 20%;
        line-height: 1.8rem;
        &.tribel {
            margin-top: 1rem;
            & .tribel-text {
                font-size: 3rem;
                color: var(--tribel-grey);
            }
            & .advisory-text {
                font-size: 1.2rem;
                color: var(--tribel-red);
            }
        }
    }
    
    & .MuiPaper-root {
        color: var(--tribel-grey);
        padding: 4rem;
        & .MuiAccordion-root {
            padding: 0;
        }
    }

    & .stepperDots {
        justify-content: center;
        width: 100%;
    }
}

.survey-subheading {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-family: 'Montserrat Light'
}
