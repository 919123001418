.register-container {
    height: 100%;
    & .register-body {
        width: 100%;
        margin: auto;
        height: 100%;
        overflow: hidden;
        position: relative;
        & .welcome-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: auto;
            height: 100%;
            gap: 1rem;
            visibility: hidden;
            position: absolute;
            top: 0;
            float: right;
            opacity: 0;
            transform: translateX(-100%);
            transition: all 0.5s cubic-bezier(0.81, 0.33, 0.42, 0.77);
            & .heading {
                flex-grow: 0.3;
                text-align: center;
                font-size: 2rem;
            }
            & button {
                margin-top: 2rem;
                border: 1px solid var(--tribel-red);
                color: var(--tribel-red);
            }
        
        }
        
        & .register-form {
            visibility: visible;
            opacity: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            margin: auto;
            height: 100%;
            gap: 1rem;
            transform: translateX(0);
            transition: all 0.5s cubic-bezier(0.81, 0.33, 0.42, 0.77);
        
            & .message {
                margin-top: 1rem;
                text-align: center;
            }
            & button {
                width: 50%;

            }
            & .error {
                color: red;
            }
            & .heading {
                flex-grow: 0.3;
                text-align: center;
                font-size: 2rem;
                color: var(--tribel-grey);
            }
        }
    }
    &.inactive {
        & .register-body {
            & .register-form {
                visibility: hidden;
                opacity: 0;
                transform: translateX(200%);
                transition: all 0.6s ease;
            }
            color: #FFF;
            & .welcome-container {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0);

            }
            
        }
    }
}