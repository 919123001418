$screen-xs-min: 0px;
$screen-sm-min: 600px;
$screen-md-min: 900px;
$screen-lg-min: 1200px;
$screen-xl-min: 1536px;


@mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
}
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }

 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }

 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }
 
 .login-container {
    height: 100%;
    & .welcome-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 50%;
        top: 50%;
        height: 100%;
        gap: 1rem;
        & .heading {
            @include xs {
                margin-top: 5rem;
            }
            @include sm {
                margin-top: 5rem;
            }
            flex-grow: 0.3;
            text-align: center;
            font-size: 2rem;
            color: var(--tribel-grey);
        }
        & .message {
            margin-top: 1rem;
            text-align: center;
        }
        & button {
            margin-top: 2rem;
        }
        & .error {
            color: red;
        }
    }
    &.inactive {
        color: #FFF;
        background-color: rgba(0,0,0,0);
        & .welcome-body {
            & .heading {
                color: #FFF;
            }
        }
        & button {
            border: 1px solid var(--tribel-red);
            color: var(--tribel-red);
        }
    }
}