
.app-container {
  margin-top: 5rem;
  &.tribel-theme {
    --tribel-grey: #576573;
    --tribel-red:  #EA6B66;
    font-family: Montserrat, system-ui, sans-serif;
  }
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.Node-logo {
  height: 30vmin;
  pointer-events: none;
}

.logo-box {
  padding-right: 4rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #003948;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
