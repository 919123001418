.forgot-container {
    width: 40%;
    margin:auto;
    height: 80vh;
    border: 1px solid var(--tribel-grey);
    position: relative;
    
    & .logo {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        width: 20%;
        line-height: 1.8rem;
        &.tribel {
            text-align: right;
            margin-top: 1rem;
            & .tribel-text {
                font-size: 3rem;
                color: var(--tribel-grey);
            }
            & .advisory-text {
                font-size: 1.2rem;
                color: var(--tribel-red);
            }
        }
    }
    & .welcome-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 50%;
        top: 50%;
        height: 80%;
        gap: 1rem;
        & .heading {
            flex-grow: 0.3;
            text-align: center;
            font-size: 2rem;
            color: var(--tribel-grey);
        }
        & .message {
            margin-top: 1rem;
            text-align: center;
        }
        & button {
            margin-top: 2rem;
            width: 50%;
        }
        & .error {
            color: red;
        }
    }
}