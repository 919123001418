$screen-xs-min: 0px;
$screen-sm-min: 600px;
$screen-md-min: 900px;
$screen-lg-min: 1200px;
$screen-xl-min: 1536px;

@mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
}
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
.auth-container {
    width: 80%;
    margin:auto;
    display: flex;
    min-height: 80vh;
    border: 1px solid var(--tribel-grey);
    position: relative;
    & .inactive-background {
        position: absolute;
        background-color: var(--tribel-grey);
        z-index: -1;
        transition: all 0.5s cubic-bezier(0.81, 0.33, 0.42, 0.77);
        @include xs {
            width: 100%;
            height: 55%;
        }
        @include md {
            width: 50%;
            height: 100%;
        }
        &.right {        
            @include xs {
               transform: translateY(100%);
               height: 38%;
               top: 24%;
            }               
            @include md {
               transform: translateX(100%);
               height: 100%;       
               top: 0%;
            }        
        }
    }
    & .inactive {
        color: white;
        & .logo {
            &.tribel{
                & .tribel-text {
                    color: #FFF;
                }
            }
        }
    }
    & .logo {
        position: absolute;
        padding-top: 0.5rem;
        padding-left: 1rem;
        line-height: 1.8rem;
        &.tribel {
            text-align: right;
            margin-top: 1rem;
            & .tribel-text {
                font-size: 3rem;
                color: var(--tribel-grey);
            }
            & .advisory-text {
                font-size: 1.2rem;
                color: var(--tribel-red);
            }
        }
    }
}